import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { reaction, computed, action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Message, Icon, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { TargetFile, TargetTextInput, TargetSelect, TargetNumberInput, TargetCheckbox, TargetDatePicker } from '@code-yellow/spider'
import { omit } from 'lodash'
import { OPERATOR_ICONS } from 'container/Process/Step/Form'
import StorageLocationSelect from './StorageLocationSelect'
import Batches from './Batches'
import SubBatches from './SubBatches'
import Decimal from 'decimal.js'
import { DateTime } from 'luxon';
import QuantityField from './QuantityField';

// components
import { MetafieldValue } from 'component/Metafields'
import { MaterialPlan } from 'component/MaterialPlan/FormStep';
import NestRequestDetails from 'component/NestRequestDetails'
// end components

// helpers
import sortSteps from 'helpers/sortSteps'
import { getCounts } from 'helpers/counts'
import { onEnter } from 'helpers'
import { humanReadable } from 'helpers/decimal';
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
// end helpers

// stores
import { Form as FormModel } from 'store/Form'
import { Operator } from 'store/Operator'
import { ProductionRequest } from 'store/ProductionRequest';
import { Warehouse } from 'store/Warehouse';
import { StorageLocation } from 'store/StorageLocation';
import { Step } from 'store/Step';
import { Batch } from 'store/Batch'
// end stores

const SmallForm = styled(Form)`
  max-hight: 900px !important;
  margin: 0 auto 50px auto;
  padding: 20px;
`


const FormStepTable =  styled(Table)`
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;

  /* Small rows, no padding */
  td, th {
    padding: 0 !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    vertical-align: middle !important;
  }
  /* Some spacing around direct descendants in VALUE column */
  td > * {
    margin-bottom: 9px !important;
    margin-top: 9px !important;
  }

  > tbody > tr > td:nth-child(3) > .ui.form > .fields {
    margin-bottom: 0 !important;
  }
`

const BigTargetCheckbox = styled(TargetCheckbox)`
  > .checkbox {
    transform: scale(1.25);
    transform-origin: 0 50%;
  }
`

function errorsAtPath(errors, ...filterPath) {
    const errorsAtPath = []
    // eslint-disable-next-line
    for (const { path = [], ...error } of errors) {
        if (path.length >= filterPath.length && filterPath.every((key, i) => path[i] === key)) {
            errorsAtPath.push({ path: path.slice(filterPath.length), ...error })
        }
    }
    return errorsAtPath
}


@observer
export default class FormFields extends Component {
    static propTypes = {
        productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
        step: PropTypes.instanceOf(Step).isRequired,
        batch: PropTypes.instanceOf(Batch).isRequired,
        data: PropTypes.object.isRequired,
        batchSize: PropTypes.number.isRequired,
        warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Warehouse)).isRequired,
        currentWarehouse: PropTypes.instanceOf(Warehouse),
        storageLocations: PropTypes.arrayOf(PropTypes.instanceOf(StorageLocation)).isRequired,
        quantityTodo: PropTypes.number.isRequired,
        errors: PropTypes.array.isRequired,
        setFormData: PropTypes.func.isRequired,
        setErrors: PropTypes.func.isRequired,
        form: PropTypes.instanceOf(FormModel).isRequired,
        disabled: PropTypes.bool,
        extraFields: PropTypes.array,
        onFocus: PropTypes.func.isRequired,
        onAddRef: PropTypes.func.isRequired,
        onChangeMaterialTask: PropTypes.func.isRequired,
        onChangeQuantity: PropTypes.func.isRequired,
        onPrintProgress: PropTypes.func.isRequired,
        disableTaskPrintButton: PropTypes.bool.isRequired,
        operator: PropTypes.instanceOf(Operator)
    }

    static defaultProps = {
        disabled: false,
        extraFields: <></>,
    }

    @observable storageLocation = null
    @observable variableQuantity = null
    @observable stepCounts = null
    @observable quantityAtCurrentStep = null
    @observable quantityAtCurrentPlusPreviousSteps = null

    constructor(...args) {
        super(...args)

        // On mount, calculate counts. They shouldn't change until the next mount,
        // there is no need to keep recalculating them.
        const { productionRequest, form, step, batchSize } = this.props
        const steps = sortSteps(productionRequest.processVersion.steps)
        this.stepCounts = getCounts({
            steps: steps,
            quantity: productionRequest.quantity,
            batches: productionRequest.batches,
            superrequestAtSubprocesses: productionRequest.superrequestAtSubprocesses,
            subrequestsFinished: productionRequest.subrequestsFinished,
        })
        // Quantity at the current step. Key names of Counts are a bit confusing, but oh well..
        this.quantityAtCurrentStep = this.stepCounts[`pre_${step.id}`]
        // Quantity at the current step + all previous steps.
        this.quantityAtCurrentPlusPreviousSteps = this.stepCounts[`total_post_${step.id}`]

        // If we find a quantity field, we set the variable quantity to the max possible at this step
        if (form.fields.models.some((field) => field.type === 'quantity')) {
            this.variableQuantity = this.quantityAtCurrentStep
        }
        // Otherwise, the batch size is already fixed. Set it to the given value.
        else {
            this.variableQuantity = batchSize
        }
    }

    componentDidMount() {
        const { form, onFocus } = this.props
        this.disposeReaction = reaction(
            () => form.fields.map((f) => f.dataSource.id),
            (dataSourceIds) => {
                if (this.dataSourceSubscriptions) {
                    this.dataSourceSubscriptions.forEach((s) => s.unsubscribe())
                }
                this.dataSourceSubscriptions = []
                dataSourceIds.forEach((id) => {
                    if (id) {
                        this.dataSourceSubscriptions.push(
                            this.subscribe({ type: 'data-source-data', data_source: id }, (data) => this.processDataSourceData(id, data))
                        )
                    }
                })
            },
            { fireImmediately: true }
        )

        onFocus(0)
    }

    componentWillUnmount() {
        this.disposeReaction()
    }

    processDataSourceData(dataSourceId, { data }) {
        const { form, setFormData } = this.props

        form.fields.forEach((f) => {
            if (f.dataSource.id === dataSourceId && data.data_points && data.data_points[f.dataName]) {
                setFormData(f.id, data.data_points[f.dataName])
            }
        })
    }

    @computed get generalErrors() {
        const { errors } = this.props
        return errors.filter(({ path }) => path.length === 1 && path[0] === 'form_data')
    }

    @computed get allMetavalues() {
        const { productionRequest, batch } = this.props

        const allMetavalues = {}

        // eslint-disable-next-line
        for (const metavalue of productionRequest.metavalues.models) {
            allMetavalues[metavalue.metafield.id] = metavalue.value
        }

        function addBatch(batchId) {
            const batch = productionRequest.batches.get(batchId)
            if (batch === undefined) {
                return
            }
            // eslint-disable-next-line
            for (const metavalue of batch.metavalues.models) {
                allMetavalues[metavalue.metafield.id] = metavalue.value
            }
            // eslint-disable-next-line
            for (const batchUsage of batch.batchUsings.models) {
                addBatch(batchUsage.usedBatch.id)
            }
        }

        if (batch.batches !== undefined) {
            // eslint-disable-next-line
            for (const batchId of Object.keys(batch.batches)) {
                addBatch(batchId)
            }
        }

        return allMetavalues
    }

    submitField(index) {
        const { onFocus } = this.props
        onFocus(index + 1)
    }

    render() {
        const {
            form, data, batchSize, warehouses, storageLocations, currentWarehouse, productionRequest, onFocus,
            quantityTodo, errors, setFormData, setErrors, disabled, extraFields, onAddRef, onChangeMaterialTask,
            operator, step, onChangeQuantity, onPrintProgress, disableTaskPrintButton
        } = this.props

        const showStorageLocation = productionRequest.productionOrder.billOfMaterialVersion.items.models.some((item) => !item.backflush)
        const hasBomField = form.fields.find(({ type }) => type === 'bom') !== undefined
        const colSpan = hasBomField && showStorageLocation ? 4 : 3;

        let displayFormFieldFullWith = false;

        return (
            <SmallForm>
                {this.generalErrors.length > 0 && (
                    <Message error
                        header={t('workStation.performModal.generalErrors')}
                        list={this.generalErrors.map(({ message }) => message)}
                    />
                )}
                <FormStepTable data-test-form-step striped basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing width={2}>{t('formStepField.field.label.label')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('formStepField.field.target.label')}</Table.HeaderCell>
                            {hasBomField && showStorageLocation && <Table.HeaderCell>
                                {t('formStepField.field.storageLocations.label')}
                            </Table.HeaderCell>}
                            <Table.HeaderCell>{t('detail.field.value.label')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {/* Show NestRequest details about the sheet if this is a cutting step */}
                        {(form.fields.length === 0 && !productionRequest.nestRequest.isNew) && (
                            <NestRequestDetails nestRequest={productionRequest.nestRequest} />
                        )}
                        {form.fields.map((field, i) => {
                            const key = field.id.toString()

                            const targetProps = {
                                noLabel: true,
                                autoFocus: i === 0,
                                onChange: (value) => setFormData(key, value),
                                contentProps: {
                                    ref: (ref) => {
                                        if (ref) {
                                            onAddRef(`field_${i}`, ref)
                                        }
                                    },
                                },
                                errors: errorsAtPath(errors, 'form_data', key).map(({ message }) => message),
                                disabled: disabled || !!field.dataSource.id,
                            }

                            if (productionRequest.processVersion.batchType.type === 'make' &&
                                (field.type === 'material_plan_task' || field.type === 'material_plan_material')
                            ) {
                                const type = field.type === 'material_plan_task' ? 'task' : 'material'

                                return (
                                    <React.Fragment>
                                        <br />
                                        <Table.Row><Table.Cell colSpan={colSpan}><b>{t(`formStepField.field.materialPlan.${type}s`)}</b></Table.Cell></Table.Row>
                                        <MaterialPlan
                                            productionRequest={productionRequest}
                                            step={step}
                                            type={type}
                                            targetProps={targetProps}
                                            colSpan={colSpan}
                                            getErrors={(bomItemId) => errorsAtPath(errors, 'form_data', key, bomItemId.toString())}
                                            generalErrors={errors.filter(({ path }) => path.length === 0)}
                                            quantityTodo={quantityTodo}
                                            required={productionRequest.quantity / (productionRequest.productionOrder.materialPlan.defaultBatchQuantity || 1)}
                                            batchSize={this.variableQuantity || batchSize}
                                            value={data.form_data[key] || {}}
                                            onChange={action(async (item, value, itemsFinished, quantityDone) => await onChangeMaterialTask(key, type, item, value, itemsFinished, quantityDone))}
                                            onConfirm={action((lastItem) => {
                                                if (!lastItem) {
                                                    this.submitField(i)
                                                }
                                            })}
                                            onPrintProgress={onPrintProgress}
                                            disableTaskPrintButton={disableTaskPrintButton}
                                            variableQuantity={this.variableQuantity}
                                            fieldRequired={field.required}
                                            operator={operator}
                                            taskIsTimed={field.materialPlanTaskIsTimed}
                                        />
                                    </React.Fragment>
                                )
                            } else if (field.type === 'bom' || field.type === 'assembly_bom') {
                                // {production-request-bom-version}
                                let billOfMaterialVersion = null
                                if (field.type === 'bom') {
                                    billOfMaterialVersion = productionRequest.productionOrder.billOfMaterialVersion
                                } else {
                                    billOfMaterialVersion = productionRequest.articleType.billOfMaterialVersions.find((v) => v.default && v.type === 'assembly_bom')
                                    // HACK: For some reason, productionRequest.productionOrder.billOfMaterialVersion does not work for assembly_bom
                                }
                                /* T40340: check if field.type = bom.type
                                  in the ticket we have field = aBom while bom.type = regular bom) */
                                if (billOfMaterialVersion &&
                                    ((field.type === 'assembly_bom' && billOfMaterialVersion.type === 'assembly_bom') ||
                                        (field.type === 'bom' && billOfMaterialVersion.type !== 'assembly_bom'))
                                ) {
                                    return (
                                        <React.Fragment key={field.cid}>
                                            <Table.Row><Table.Cell colSpan={colSpan}><b data-test-field-type={field.type}>{field.type === 'assembly_bom' ? 'ASSEMBLY' : ''} BOM</b></Table.Cell></Table.Row>
                                            {billOfMaterialVersion.items.map((item, j) => {
                                                // If no default Batch Quantity -> assume 1. Fixes a bug with assembly bom
                                                // TODO: Move decimal to model and fix this line.
                                                const quantity = Decimal(Math.ceil((billOfMaterialVersion.defaultBatchQuantity || 1) * item.quantity * batchSize))
                                                return (
                                                    <Table.Row key={item.cid}>
                                                        <Table.Cell>{field.label} ({j + 1} / {billOfMaterialVersion.items.length}){!field.required && <i>{t('formStep.field.optional.label')}</i>}</Table.Cell>
                                                        <Table.Cell><div>
                                                            {item.articleType.getLabel()} {item.articleType.name} {item.articleType.extraDescription} ({humanReadable(quantity)})
                                                        </div></Table.Cell>
                                                        {showStorageLocation &&
                                                            <Table.Cell>{productionRequest.materialIssues
                                                                .filter(({ articleType }) => (
                                                                    articleType.id === item.articleType.id
                                                                ))
                                                                .map(({ quantity, storageLocation }) => `${storageLocation.code} (${Math.ceil(quantity)})`).join(', ')}
                                                            </Table.Cell>
                                                        }
                                                        <Table.Cell>
                                                            <Batches
                                                                {...targetProps}
                                                                articleType={item.articleType}
                                                                quantity={quantity}
                                                                value={(data.form_data[key] || {})[item.articleType.id]}
                                                                errors={errorsAtPath(errors, 'form_data', key, item.articleType.id.toString())}
                                                                autoFocus={i === 0 && j === 0}
                                                                ref={(ref) => {
                                                                    if (ref) {
                                                                        onAddRef(`field_${i}${j !== 0 ? `_${j}` : ''}`, ref)
                                                                    }
                                                                }}
                                                                onChange={action((value) => {
                                                                    setFormData(key, { ...data.form_data[key] || {}, [item.articleType.id]: value })
                                                                    setErrors(errors.filter(({ path }) => !(path.length >= 3 && path[0] === 'form_data' && path[1] === key && path[2] === item.articleType.id.toString())))
                                                                })}
                                                                onConfirm={action((value) => {
                                                                    if (j < billOfMaterialVersion.items.length - 1) {
                                                                        onFocus(i, j + 1)
                                                                    } else {
                                                                        this.submitField(i)
                                                                    }
                                                                })}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={field.cid}></React.Fragment>
                                    )
                                }
                            }

                            let fieldDetails = null
                            if (field.type === 'article_type') {
                                fieldDetails = (
                                    <>
                                        {field.articleType.code ? field.articleType.code + ': ' : ''}{field.articleType.name}{field.articleTypeBatchSize ? ` (${batchSize})` : ''}
                                        {field.scanConstraints.map((scanConstraint) => {
                                            const rightValue = scanConstraint.rightValue ?? this.allMetavalues[scanConstraint.rightMetafield.id]
                                            return (
                                                <div key={scanConstraint.cid} data-test-constraint>
                                                    <b>{scanConstraint.leftMetafield.name}</b>
                                                    {' '}
                                                    <Icon name={OPERATOR_ICONS[scanConstraint.operator]} />
                                                    {' '}
                                                    {Array.isArray(rightValue) ? rightValue.join(', ') : rightValue?.toString()}
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                            } else if (field.type === 'measure') {
                                fieldDetails = t('formStep.perform.between', { min: field.measureMin, max: field.measureMax })
                            } else if (field.type === 'best_before_date') {
                                fieldDetails = t('formStep.perform.bestBeforePeriod', { best_before_period: field.bestBeforePeriod })
                            }

                            let fieldContent = (
                                <TargetTextInput
                                    fluid
                                    {...targetProps}
                                    value={data.form_data[key] || ''}
                                    onKeyPress={onEnter(() => this.submitField(i))}
                                />
                            )
                            if (field.type === 'check') {
                                fieldContent = (
                                    <BigTargetCheckbox
                                        toggle
                                        {...targetProps}
                                        value={data.form_data[key] || false}
                                        onKeyPress={onEnter(
                                            action(() => {
                                                setFormData(key, true)
                                                this.submitField(i)
                                            })
                                        )}
                                        afterChange={() => this.submitField(i)}
                                    />
                                )
                            } else if (field.type === 'choice') {
                                fieldContent = (
                                    <TargetSelect
                                        fluid
                                        {...targetProps}
                                        value={data.form_data[key] || null}
                                        options={field.options.map((option) => ({ value: option, text: option }))}
                                        onKeyPress={onEnter(() => {
                                            this.submitField(i)
                                        })
                                        }
                                    />
                                )
                            } else if (field.type === 'measure') {
                                fieldContent = (
                                    <Form>
                                        <Form.Group>
                                            <TargetNumberInput
                                                allowDecimal
                                                data-test-form-step-field-measure={field.id}
                                                {...targetProps}
                                                contentProps={{
                                                    ...omit(targetProps.contentProps, 'ref'),
                                                    innerRef: targetProps.contentProps.ref,
                                                }}
                                                width={8}
                                                value={data.form_data[key] ? data.form_data[key].value : null}
                                                onChange={(value) =>
                                                    setFormData(key, {
                                                        value,
                                                        reason:
                                                            value === null || (value >= field.measureMin && value <= field.measureMax)
                                                                ? null
                                                                : (data.form_data[key] && data.form_data[key].reason) || '',
                                                    })
                                                }
                                                toTarget={(value) => (value === '' ? null : parseFloat(value))}
                                                fromTarget={(value) => (value === null ? '' : value.toString())}
                                                onKeyPress={onEnter(() => {
                                                    const value = data.form_data[key] ? data.form_data[key].value : null
                                                    if (value === null || (value >= field.measureMin && value <= field.measureMax)) {
                                                        this.submitField(i)
                                                    } else {
                                                        this[`field_reason_${i}`].focus()
                                                    }
                                                })}
                                                errors={
                                                    errors
                                                        .filter(({ path }) => (
                                                            path.length >= 2 &&
                                                            path[0] === 'form_data' &&
                                                            path[1] === field.id.toString() &&
                                                            (path.length === 2 || path[2] !== 'reason')
                                                        ))
                                                        .map(({ message }) => message)
                                                }
                                            />
                                            <TargetTextInput
                                                noLabel
                                                name="reason"
                                                width={8}
                                                value={data.form_data[key] ? data.form_data[key].reason : ''}
                                                contentProps={{
                                                    ref: (ref) => {
                                                        if (ref) {
                                                            this[`field_reason_${i}`] = ref
                                                        }
                                                    },
                                                }}
                                                placeholder={t('detail.field.reason.label')}
                                                onChange={(reason) =>
                                                    setFormData(key, {
                                                        value: data.form_data[key] ? data.form_data[key].value : null,
                                                        reason,
                                                    })
                                                }
                                                onKeyPress={onEnter(() => this.submitField(i))}
                                                errors={errorsAtPath(errors, 'form_data', field.id.toString(), 'reason').map(({ message }) => message)}
                                                disabled={
                                                    targetProps.disabled ||
                                                    !data.form_data[key] ||
                                                    data.form_data[key].reason === null
                                                }
                                            />
                                        </Form.Group>
                                    </Form>
                                )
                            } else if (field.type === 'image') {
                                fieldContent = (
                                    <>
                                        {(data.form_data[key] || []).map((file, i) => (
                                            <TargetFile noPreview fluid
                                                {...targetProps}
                                                key={i}
                                                errors={undefined}
                                                value={file}
                                                onChange={(file) => setFormData(key, [
                                                    ...data.form_data[key].slice(0, i),
                                                    file,
                                                    ...data.form_data[key].slice(i + 1),
                                                ])}
                                                onDelete={() => setFormData(key, [
                                                    ...data.form_data[key].slice(0, i),
                                                    ...data.form_data[key].slice(i + 1),
                                                ])}
                                                accept="image/*"
                                            />
                                        ))}
                                        <TargetFile noPreview fluid
                                            {...targetProps}
                                            value={null}
                                            onChange={(file) => setFormData(key, [
                                                ...data.form_data[key] || [],
                                                file,
                                            ])}
                                            accept="image/*"
                                        />
                                    </>
                                )
                            } else if (field.type === 'article_type') {
                                fieldContent = (
                                    <Batches
                                        {...targetProps}
                                        articleType={field.articleType}
                                        constraints={field.scanConstraints}
                                        allMetavalues={this.allMetavalues}
                                        quantity={field.articleTypeBatchSize ? batchSize : Decimal(1)}
                                        ref={targetProps.contentProps.ref}
                                        errors={errorsAtPath(errors, 'form_data', key)}
                                        onConfirm={action((value) => {
                                            this.submitField(i)
                                        })}
                                    />
                                )
                            } else if (field.type === 'quantity') {
                                fieldContent = (
                                    <QuantityField
                                        quantity={data.form_data[key]}
                                        suffix={
                                            field.quantityUnitWeightUnit ||
                                            (isFeatureFlagEnabled('allow_receipt_of_more_than_ordered') ? undefined : `    / ${this.quantityAtCurrentStep}`)
                                        }
                                        maxQuantity={this.quantityAtCurrentPlusPreviousSteps}
                                        onEnter={() => onEnter(() => this.submitField(i))}
                                        onChange={(value) => {
                                            this.variableQuantity = value
                                            onChangeQuantity(key, this.variableQuantity)
                                        }}
                                    />
                                )
                            } else if (field.type === 'storage_location') {
                                fieldContent = (
                                    <StorageLocationSelect
                                        {...targetProps}
                                        articleType={productionRequest.processVersion.batchType.articleType}
                                        onChange={action((value) => {
                                            targetProps.onChange(value)
                                            this.storageLocation = storageLocations.find((st) => st.id === value)
                                            this.submitField(i)
                                        })}
                                        warehouses={warehouses}
                                        storageLocations={storageLocations}
                                        defaultWarehouse={currentWarehouse}
                                        suggestLocation={isFeatureFlagEnabled('suggest_locations')}
                                        quantity={this.variableQuantity || batchSize}
                                        ref={targetProps.contentProps.ref}
                                    />
                                )
                            } else if (field.type === 'metafield') {
                                fieldContent = (
                                    <MetafieldValue
                                        {...targetProps}
                                        metafield={field.metafield}
                                        value={data.form_data[key] ?? {
                                            value: field.metafield.defaultValue,
                                            file: field.metafield.defaultFile,
                                        }}
                                    />
                                )
                            } else if (field.type === 'sub_batches') {
                                fieldContent = (
                                    <SubBatches
                                        productionRequest={productionRequest}
                                        step={step}
                                        targetProps={targetProps}
                                        data={data}
                                        field={field}
                                        key={key}
                                        errors={errors}
                                        errorsAtPath={errorsAtPath}
                                        onAddRef={onAddRef}
                                        onFocus={(j) => onFocus(i, j)}
                                        autoFocus={i === 0}
                                        index={i}
                                        operator={operator}
                                    />
                                )
                                displayFormFieldFullWith = true;
                            } else if (field.type === 'best_before_date') {
                                const bestBeforeDate = DateTime.local().plus({ days: field.bestBeforePeriod }).toFormat('yyyy-LL-dd')

                                fieldContent = <TargetDatePicker
                                    {...targetProps}
                                    value={DateTime.fromISO(data.form_data[key] || bestBeforeDate)}
                                    afterChange={() => data.form_data[key] = DateTime.fromISO(data.form_data[key] || bestBeforeDate).toFormat('yyyy-LL-dd')}
                                    onKeyPress={onEnter(() => this.submitField(i))}
                                />
                            }

                            if (displayFormFieldFullWith) {
                                return (
                                    <tr key={field.cid}>
                                        <td colSpan={3}>{fieldContent}</td>
                                    </tr>
                                )
                            }

                            return (
                                <Table.Row key={field.cid}>
                                    <Table.Cell data-test-batch-metafield-label>
                                        {field.type === 'metafield' ? field.metafield.name : field.label}
                                        {!field.required && <i>{t('formStep.field.optional.label')}</i>}
                                    </Table.Cell>
                                    <Table.Cell>{fieldDetails}</Table.Cell>
                                    {showStorageLocation && hasBomField && <Table.Cell ></Table.Cell>}
                                    <Table.Cell>{fieldContent}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                        {extraFields}
                    </Table.Body>
                </FormStepTable>
            </SmallForm>
        )
    }
}
